import { EditorSDK } from '@wix/platform-editor-sdk';
import { APP_MANAGER_ACTIONS_EVENTS, EVENTS } from '../constants';
import * as appActionEventHandler from './app-action-event-handler';
import { initTranslateFunction, t } from '../utils/i18n';
import { getBaseUrl } from '../utils/baseUrl';

export const editorReady = async (
  editorSDK: EditorSDK,
  _token,
  { initialAppData },
) => {
  const baseUrl = await getBaseUrl(editorSDK);
  await initTranslateFunction(initialAppData.languageCode, baseUrl);
  await editorSDK.addEventListener(EVENTS.appActionClicked, (event) =>
    appActionEventHandler.handleEvent(event, editorSDK),
  );
};

export const getAppManifest = () => ({
  appDescriptor: {
    mainActions: [
      {
        title: t('manageApp.openSettings'),
        actionId: APP_MANAGER_ACTIONS_EVENTS.openSettings,
        icon: 'appManager_settingsAction',
        type: 'settings',
      },
      {
        title: t('manageApp.openInbox'),
        actionId: APP_MANAGER_ACTIONS_EVENTS.openInbox,
        icon: 'appManager_pagesAction',
        type: 'settings',
      },
    ],
    customActions: [
      {
        title: t('manageApp.scheduleHours'),
        actionId: APP_MANAGER_ACTIONS_EVENTS.scheduleHours,
        icon: 'appManager_settingsAction',
        type: 'editorActions',
      },
      {
        title: t('manageApp.setEmailNotifications'),
        actionId: APP_MANAGER_ACTIONS_EVENTS.setEmailNotifications,
        icon: 'lineIcon_email',
        type: 'editorActions',
      },
      {
        title: t('manageApp.createAutomations'),
        actionId: APP_MANAGER_ACTIONS_EVENTS.createAutomations,
        icon: 'appManager_addElementsAction',
        type: 'editorActions',
      },
    ],
    defaultActions: {
      learnMoreKB: '2538fa4b-388f-4f21-ab62-0980f7b870ab',
    },
  },
});
